var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "misc-wrapper" },
    [
      _c("b-link", { staticClass: "brand-logo" }, [_c("vuexy-logo")], 1),
      _c("div", { staticClass: "misc-inner p-2 p-sm-3" }, [
        _c(
          "div",
          { staticClass: "w-100 text-center" },
          [
            _c("h2", { staticClass: "mb-1" }, [
              _vm._v(" Acesso não autorizado! 🔐 "),
            ]),
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(" Você não tem permissão para acessar esta página. "),
            ]),
            _c(
              "b-button",
              {
                staticClass: "mb-1 btn-sm-block",
                attrs: { variant: "primary", to: _vm.loginRoute() },
              },
              [_vm._v("Voltar")]
            ),
            _c("b-img", {
              attrs: { fluid: "", src: _vm.imgUrl, alt: "Not authorized page" },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }